<template>
  <div>
    <ExtendedView
      :tabs="tabs"
      :selected-object="selectedApp"
      @clearSelectedObject="selectedApp = null"
    >
      <template v-slot:list="{ item }">
        <ActionList
          :key="item.key"
          :fixed-filters="getFilter(item.key)"
          :filters="allAppsFilters"
          :load-items-callback="loadItemsCallback"
          @actionSuccess="loadItemsCallback"
          @actionFailure="loadItemsCallback"
          empty-icon="mdi-credit-card-outline"
          :empty-text="$t('noAppsFound')"
          v-on="$listeners"
        >
          <template v-slot:item="{ item, index }">
            <AppListItem
              :app="item"
              :key="item.uuid"
              v-on="$listeners"
              @selectApp="selectedApp = item"
            />
            <v-divider
              inset
              :key="index"
            />
          </template>
        </ActionList>
      </template>

      <template slot="profile">
        <AppProfile
          v-if="selectedApp"
          :app="selectedApp"
        />
      </template>
    </ExtendedView>
  </div>
</template>

<script>
  import ExtendedView from "@/components/common/ExtendedView"
  import AppProfile from "@/components/apps/AppProfile"
	import AppListItem from "@/components/apps/AppListItem"
	import ActionList from "@/components/common/list/ActionList"

  export default {
    name: 'Payments',
    components: {
      AppProfile,
      ExtendedView,
			AppListItem,
			ActionList
    },
    data: () => ({
      sort: 'desc',
      selectedApp: null,
      loading: false,
			allAppsFilters: [
      ],
    }),
    methods: {
			async loadItemsCallback({conditions}) {
        const appData = await this.$store.dispatch('getApplications')

        if(conditions[0]?.value === 'installed') {  
          const installedAppIds = appData.installedApps.map(app => app.uuid)
          const result = appData.apps.filter(app => installedAppIds.includes(app.uuid))
          return result
        } else {
          return appData.apps
        }
			},
      getFilter(value) {
        return [{
          key: 'type',
          value,
          operator: 'equals'
        }]
      }
    },
    computed: {
      showBottomSheet: {
        get() {
          return this.selectedPayment !== null
        },
        set(value) {
          this.selectedPayment = null
        }
		  },
      tabs() {
        return [
          {
            title: this.$t('all'),
            key: 'all'
          },
          {
            title: this.$t('installed'),
            key: 'installed'
          }
        ]
      },
      selectedAppBarTab() {
        return this.$store.state.selectedAppBarTab
      },
      actionSuccess() {
        return this.$store.state.actionSuccess
      },
      appGroups() {
        return this.$store.state.appGroups
      }
    },
    mounted() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
	  },
    created() {
      
    },
    watch: {
      selectedApp(newApp, oldApp) {
        this.$store.dispatch('currentAppReleases', newApp.uuid)
      }
    }
  }
</script>
